.service-page .service-header {
    position: relative;
    height: 63vh;
    overflow: hidden;
    margin-bottom: 4rem;
}
.service-page .lierEffectImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--lier-fadeRight);
}
.service-page .service-bgImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    filter: brightness(.8);
}

.service-page .img-content{
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
}

.service-page .service-title{
    color: #fff;
    font-size: 3.5rem;
    font-weight: 600;
    max-width: 500px;

}


.service-page .service-subTitle{
    padding: 25px;
    max-width: 75%;
    font-size: 2.3rem;
    margin-bottom: 2.5rem;
}
.service-page .para-container{
    padding-inline: 25px 35px;
    max-width: 860px;
    margin: auto;
}
.service-page .service-paragraph{
    margin-bottom: 6rem;
    font-size: 1.5rem;
    color: var(--font-secondaryColor);
}

.service-page .point{
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 1.5rem;
}
.service-page .point-icon{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 7px #ed1c2334; 
    background-color: var(--color-primary);
}

@media (max-width:992px) {
    .service-page .para-container{
        max-width: 835px;
    }
    .service-page .service-paragraph{
        font-size: 1.1rem;
    }
}
@media (max-width:767px) {
    .service-page .lierEffectImg {
        background: linear-gradient(to right, var(--lier-fade) 0%, rgba(255, 255, 255, 0) 70%);
    }
    .service-page .para-container{
        max-width: 100%;
    }
    .service-page .service-paragraph{
        font-size: .9rem;
    }
    .service-page .service-subTitle{
        max-width: 100%;
        padding-bottom: 0px;
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
}
@media (max-width:567px) {
    .service-page .service-title{
        font-size: 2.5rem;
        max-width: 450px;
    }
}
.Loading-screen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--bg-loading);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9000;
}

.Loading-screen.active {
    display: flex;
}

.Loading-screen:not(.active) {
    display: none;
}

.Loading-screen .loader {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    perspective: 800px;
}

.Loading-screen .loader .inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;  
}

.Loading-screen .loader .inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 3px solid var(--color-primary);
}

.Loading-screen .loader .inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 3px solid var(--color-primary);
}

.Loading-screen .loader .inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid var(--color-primary);
}

@keyframes rotate-one {
    0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}
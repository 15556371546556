
.section-title{
    text-align: center;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.all-servicesCard{
    padding: 0px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1px;
    transition: var(--transition-main);
}
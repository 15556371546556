@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Define dark mode variables */
:root {
    /* Primary Colors */
    --color-primary         : #ed1c24;
    --color-secondary       : #010a1a;
    --color-tertiary        : #fff;


    /* Background Colors */
    --bg-body               : #010a1a;
    --bg-nav                : var(--bg-body);
    --bg-container          : var(--glassBG-light);
    --bg-section            : #1A1A1A;
    --bg-component          : #fff;
    --bg-smallComponent     : transparent;
    --bg-footer             : #1a2331;
    --bg-loading            :  radial-gradient(circle farthest-corner at center, #010a1a 0%, #0d192e 100%);
    --bg-scrolled           : #1a2331c4;
    --lier-fade             : linear-gradient(transparent, var(--bg-body));
    --lier-fadeRight        : linear-gradient(to right, var(--bg-body) 0%, rgba(255, 255, 255, 0) 40%);
    --lier-opacity          : .8;

    /*border color*/
    --lightBorder-color     : #ffffff31;

    /* Font Colors */
    --font-mainColor        : #FFF;
    --font-secondaryColor   : #80858d;
    --font-tertiaryColor    : #010a1a;
    --font-navColor         : #fff;
    --font-navColorSec      : #fff ;
    --font-navColorThir     : #fff ;
    --font-veryLightColor   : #777 ;
    --font-iconTheme        : #fff ;

    /* ================================================== */
    /* ================================================== */

    /* Other */
    --transition-main       : all .3s ease;
    --transition-animation  : all .2s ease-in-out;
    /* --distance-main         : 75px;
    --margin-main           : calc(var(--distance-main) - ( var(--distance-main) / 2 ));
    --padding-main          : calc(var(--distance-main) - ( var(--distance-main) / 3 )); */
}

/* Define light mode variables */
[data-theme='light'] {

    /* Background Colors */
    --bg-body               : #fff;
    --bg-nav                : var(--bg-body);
    --bg-container          : var(--glassBG-light);
    --bg-section            : #1A1A1A;
    --bg-component          : #fff;
    --bg-smallComponent     : #fff;
    --bg-footer             : #f2f2f2;
    --bg-loading            :  background-image: radial-gradient(circle farthest-corner at center, #f0f4f8 0%, #ffffff 100%);    ;
    --bg-scrolled           : #ffffffea;
    --lier-fade             : unset;
    --lier-fadeRight        : unset;
    --lier-opacity          : 1;

    /*border color*/
    --lightBorder-color     : #00000021;

    /* Font Colors */
    --font-mainColor        : #010a1a;
    --font-secondaryColor   : #616060;
    --font-tertiaryColor    : #fff;
    --font-navColor         : #010a1a;
    --font-navColorSec      : #010a1a ;
    --font-navColorThir     : #fff ;
    --font-veryLightColor   : #999 ;
    --font-iconTheme        : var(--color-primary) ;

}






*, *::before, *::after {
    box-sizing: border-box;
}



::-webkit-scrollbar        { width: 8px;                              }
::-webkit-scrollbar-track  { background-color: var(--bg-footer); }
::-webkit-scrollbar-thumb  { background-color: var(--color-primary);  }
::-webkit-scrollbar-button { background-color: var(--color-primary);  }


body {
    font-family: "Roboto", system-ui, sans-serif;
    background-color:  var(--bg-body) ;
    color: var(--font-mainColor);
    /* transition: var(--transition-main); */
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

input{
    outline: none;
    
} 
input:focus{
    outline: none;
} 


.App{
    max-width: 1400px;
    margin: auto;
    /* padding-inline: 25px; */
}
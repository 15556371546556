.why-us .whyUs-title{
    text-align: center;
    font-weight: 600;
    font-size: 2.3rem;
    color: var(--font-mainColor);
    margin-bottom: 5rem;
}
.answer-points{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 6rem;
}
.answer-point{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.answerPoint-title svg{
    color: var(--color-primary);
    font-size: 3rem;
}
.answerPoint-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: var(--font-mainColor);
    font-size: 25px;
}
.answerPoint-text{
    margin-top: 3px;
    font-size: 18px;
    color: var(--font-secondaryColor);
}




.ready-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    color: #fff;
    text-align: center;
    padding: 3rem;
    border-radius: 8px;
}
.ready-title{
    font-size: 1.9rem;
    font-weight: 600;
}
.ready-text{
    font-weight: 500;
    margin-block: .5rem 1rem;
}
.ready-btn{
    background-color: #fff;
    width: fit-content;
    padding: 10px 35px;
    color: var(--color-primary);
    border-radius: 7px;
    border: 2px solid #fff;
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition-main);
}
.ready-btn:hover{
    background-color: transparent;
    color: #fff;
}




@media (max-width:992px) {
    .answer-points{
        gap: 4rem;
    }
}
@media (max-width:567px) {
    .ready-wrapper{
        padding: 2rem;
    }
    .ready-title{
        font-size: 1.7rem;
    }
    .ready-text{
        font-size: 14px;
        font-weight: 500;
        margin-block: .3rem .6rem;
    }
    .ready-btn{
        padding: 7px 30px;
        font-size: 15px;
    }
}
.btnTheme-wrapper{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.menu-icon {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #ffffff28;
    cursor: pointer; 
    font-size: 24px; 
    transition: var(--transition-main);
    background-color: rgba(18, 24, 29, 0.425);
}
.icon-line{
    position: relative;
    height: 2px;
    width: 20px;
    background-color: var(--color-primary);
    opacity: 1;
    transition: var(--transition-main);
}

/*open effect*/
.menu-icon.open {
    gap: 0px;
}
.menu-icon.open .line-top{
    transform: rotate(225deg);
    top: 2px;
}
.menu-icon.open .line-bott{
    transform: rotate(135deg);
    top: -2px;
}
.menu-icon.open .line-mid{
    width: 0px;
    /* height: 0px; */
    opacity: 0;
}


@media (min-width: 992px) {
    .btnTheme-wrapper {
        display: none; 
    }
}
.MainHeaderSec {
    position: relative;
    height: calc(100vh);
    overflow: hidden;
    margin-bottom: 8rem;
}
.MainHeaderSec::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%; 
    background: var(--lier-fade);
    z-index: -1; 
}

.bg-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1; 
    transform: translate(-50%, -50%);
    object-fit: cover; 
    opacity: var(--lier-opacity); 
}


.vid-titleWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    /* z-index: 1; */
}

.vid-title {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.vid-dis {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}







@media (max-width: 767px) {
    .vid-title {
        width: 350px;
    }
}
@media (max-width: 567px) {
    .vid-title {
        width: 250px;
        font-size: 2rem;
        margin-bottom: .5rem;
    }
    .vid-dis {
        font-size: 1rem;
    }
}

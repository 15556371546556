.ServiceRightSide {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    border-bottom: 1px solid var(--lightBorder-color);
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
}

.service-card {
    flex: 1 45%;
    min-width: 130px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    text-align: center;
    color: var(--font-secondaryColor);
}
.serRightMainTitle{
    margin-bottom: 1.5rem;
    font-size: 20px;
}
.rightSideSerTitle{
    width: 100%;
    text-align: start;
    font-size: 13px;
}
.rightServiceImg-wrapper {
    width: 100%;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.service-image {
    width: 100%;
    height: auto;
}

@media (max-width: 1024px) {
    .service-card {
        flex: 1 30%;
        height: 275px;
    }
    .rightServiceImg-wrapper {
        height: 200px;
    }
    .serRightMainTitle{
        font-size: 1.7rem;
    }
    .rightSideSerTitle{

        font-size: 16px;
    }
}
@media (max-width: 992px) {
    .service-card {
        flex: 1 48%;
        height: 300px;
        gap: 25px;
    }
    .rightServiceImg-wrapper {
        height: 200px;
    }
}
@media (max-width: 767px) {
    .service-card {
        flex: 1 100%;
        height: 400px;
        margin-bottom: 3rem;
    }
    .rightServiceImg-wrapper {
        height: auto;
    }
    .rightSideSerTitle{

        font-size: 25px;
    }
}
@media (max-width: 500px) {
    .service-card {
        gap: 10px;
        height: fit-content;
        margin-bottom: 1rem;
    }
    .rightSideSerTitle{
        font-size: 20px;
    }
}


.service-cardLink {
    flex: 1 0 33%;
    height: 350px;
    position: relative;
    overflow: hidden;
}

.bg-cardImg{
    width: 100%;
    height: 100%;
    filter: brightness(40%) ;
    object-fit: cover;
    transition: var(--transition-main);
}
.service-icon{
    width: 56px;
    height: auto;
    margin-bottom: 35px;
}
.service-content{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
}
.text{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-size: 1.7rem;
    text-align: center;
    /* max-width: 180px; */
    opacity: 1;
    transition: var(--transition-main);
}


.service-cardLink
.cirPrimary-btn {
    position: absolute;
    opacity: 0;
    left: 15px;
    bottom: 15px;
    transition: var(--transition-main);

}
/*start effect*/
.service-cardLink:hover 
.cirPrimary-btn {
    opacity: 1;
}
.service-cardLink:hover 
.cirPrimary-btn .bg-cirBtn{
    transform: translateX(0%);
}
.service-cardLink:hover 
.cirPrimary-btn .arrow-one1{
    transform: translate(50%, -50%);
}
.service-cardLink:hover 
.cirPrimary-btn .arrow-two2{
    transform: translate(-50%, -50%);
}

.all-servicesCard:hover 
.service-cardLink:not(:hover) .bg-cardImg{
    filter: brightness(30%);
}
.service-cardLink:hover .bg-cardImg{
    transform: scale(1.2);
    filter: brightness(100%);
}
.service-cardLink:hover .text{
    opacity: 0;
}


@media (max-width:767px) {
    .service-cardLink {
        flex: 1 0 49%;
    }
    .service-content{
        padding: 20px;
    }
    .text{
        font-size: 18px;
    }
}
@media (max-width:350px) {
    .service-cardLink {
        flex: 1 0 99%;
    }
    .service-content{
        padding: 30px;
    }
    .text{
        font-size: 20px;
    }
}
.imgCarouselWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 63vh;
    overflow: hidden;
    margin-bottom: 3rem;
}

.imgCarousel {
    display: flex;
    width: 100%; 
    height: 100%;
    transition: transform 0.5s ease-in-out;
}

.carouselSlide {
    min-width: 100%;
    height: 100%; 
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
}

.content-wrapper {
    justify-content: space-between;
    width: 100%;   
    padding: 3rem 3.25rem 2rem 3rem;
    position: absolute;
    bottom: 20px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.carousel-paragraph {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 1rem;
    transition: opacity 0.3s ease-in-out;
}

.carousel-paragraph.fade {
    opacity: 0;
}

.carousel-btn {
    width: 3rem;
    height: 3rem;
    padding: 0;
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
}

.carousel-btn:disabled {
    color: rgba(255, 255, 255, 0.35);
    cursor: not-allowed;
}

@media (max-width: 1024px) {
    .imgCarouselWrapper {
        height: 55vh;
    }

    .content-wrapper {
        width: 100%;
        bottom: 0;
        border-radius: 0;
        padding: 2rem 1.5rem 1rem;
    }
}

@media (max-width: 767px) {
    .imgCarousel {
        height: 80vh;
    }

    .imgCarouselWrapper {
        height: 45vh;
    }

    .carousel-paragraph {
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
    }
}

@media (max-width: 560px) {

    .content-wrapper {
        padding: 1.5rem 1.5rem 0;
    }

    .carousel-paragraph {
        font-size: 1rem;
    }
}

.nested-megaMenu{
    overflow: hidden;
    position: absolute;
    left: 55%;
    top: 100%;
    transform: translate(-50%, -200%);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 970px;
    gap: .08em;
    justify-content: center;
    background-color: #e2e0e0;
    border-top: 1px solid #e2e0e0;
    border-bottom: 1px solid #e2e0e0;
    transition: var(--transition-animation);
    z-index: -1;
}
.nested-megaMenu .mega-item{
    flex: 1 0 16%;
    min-width: 160px;
    position: relative;
    height: 105px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-component);
    overflow: hidden;
    transition: var(--transition-animation);
}
.nested-megaMenu .mega-item::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translateY(100%);
    background-color: #ececec;
    transition: all .2s ease-out;
}

.nested-megaMenu .item-content{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    z-index: 1;
}
.nested-megaMenu .item-icon{
    width: 25px;
    height: auto;
}
.nested-megaMenu .item-text{
    font-size: 12px;
    font-weight: 600;
    color: #010a1a;
    transition: var(--transition-main);
}


/*start effect*/
.nested-megaMenu.opened{
    transform: translate(-50%, 0%);
}
.nested-megaMenu .mega-item:hover::before{
    transform: translateY(0%);
}
.nested-megaMenu 
.mega-item:has(.mega-link.active)::before{
    transform: translateY(0%);
}

@media (max-width:1100px) {
    .nested-megaMenu{
        left: 50%;
    }
}
@media (max-width:992px) {




    
    .nested-megaMenu{
        height: 0px;
        flex-direction: column;
        position: relative;
        left: 0%;
        top: 0%;
        transform: translate(0%,0%);
        justify-content: center;
        align-items: unset;
        gap: .0em;
        background-color: transparent;
        z-index: unset;
        transition: var(--transition-animation);
        border-top: none;
        border-bottom: 0px solid transparent;
        overflow: hidden;
        width: 100%;
    }
    .nested-megaMenu::-webkit-scrollbar{
    width: 0px;
    }
    .nested-megaMenu.opened{
        padding-block: 10px;
        transform: translate(0px, 0px);
        border-bottom: 2px solid var(--color-primary);
    }
    .nested-megaMenu .mega-item{
        flex: 1;
        width: 100%;
        height: auto;
        justify-content: unset;
        background-color: transparent;
        overflow: hidden;
    }
    .nested-megaMenu .mega-link{
        padding: 5px 40px;
        display: flex;
        width: 100%;
        height: 100%;
    }
    .nested-megaMenu .item-content{
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        z-index: unset;
    }
    .nested-megaMenu .item-text{
        font-size: 14px;
        color: var(--font-mainColor);
    }
    .nested-megaMenu .mega-item::before{
        display: none;
    }
    .nested-megaMenu .mega-item:hover .item-text{
        color: var(--color-primary);
    }
    .nested-megaMenu .mega-link.active .item-text{
        color: var(--color-primary);
    }
}
.vid-btn {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(50px);
    height: calc(50px);
    border-radius: 50%;
    background-color: green;
    color: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    transition: var(--transition-main) ;
    z-index: 999;
}
.vid-btn svg{
    position: relative;
    top: -1px;
    font-size: 2rem;
    transition: var(--transition-main);
}
.vid-btn:hover svg{
    transform: scale(1.1);
}



@media (max-width:500px) {
    .vid-btn {
        bottom: 85px;
    }
}


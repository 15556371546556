/*theme-btn*/
.theme-btn{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: rgba(18, 24, 29, 0.425);
    border: 1px solid #ffffff28;
    cursor: pointer;
}
.theme-icon{
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: var(--transition-main);
}
.moon{
    opacity: 0;
    font-size: 17px;
    color: #fff;
    filter: drop-shadow( 0px 0px 5px );
}
.sun{
    opacity: 1;
    font-size: 21px;
    color: orange;
    filter: drop-shadow( 0px 0px 5px yellow);
}
.theme-btn.darkActive .moon{
    opacity: 1;
}
.theme-btn.darkActive .sun{
    opacity: 0;
}
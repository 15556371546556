.headroom--scrolled{
    z-index: 2 !important; 
}
.headroom--scrolled .the-nav{
    background-color: var(--bg-scrolled);
    transition: var(--transition-main);
}
.headroom--scrolled 
.the-nav:has(.nest-wrapper.active){
    background-color: var(--bg-body);
}
/* .headroom-wrapper{
    height: fit-content !important;
} */
.nav-container {
    position: relative;
    z-index: 999;
    max-width: 1400px;
    margin: auto;
    transition: var(--transition-main);
}

.the-nav {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 0px 25px;
    background-color: var(--bg-nav);
    transition: var(--transition-main);
    border-bottom: 1px solid #ffffff27;
    box-shadow: 0px 0px 5px 0px #00000070;
}
.logo-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.company-name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.2;
    color: var(--font-navColor);
}
.name{
    font-size: 22px;
    font-weight: 600;
}
.description{
    font-weight: 600;
    color: var(--color-primary);
    font-size: 12px;
}
.logo {
    position: relative;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #010a1a28;
}

.logo-img {
    width: 45px;
    height: auto;
}

.webLinks {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

.web-link {
    color: var(--font-navColor);
    font-weight: 500;
    font-size: 14px;
    transition: var(--transition-main);
}

.link-wrapper {
    position: relative;
    padding: 30px 15px;
    overflow: hidden;
}

.link-wrapper::after {
    content: "";
    position: absolute;
    left: -100%;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-primary);
    transition: var(--transition-main);
}


.btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 30px;
}


/* Start effect */

.web-link:hover {
    color: var(--color-primary);
}
.link-wrapper:hover::after {
    left: 0%;
}
.web-link.active{
    color: var(--color-primary);
} 
.web-link.active 
.link-wrapper::after {
    left: 0%;
}

@media (max-width: 992px) {
    .headroom--scrolled .the-nav:has(.webLinks.open){
        background-color: var(--bg-body);
    }
    .the-nav{
        padding-block: 15px;
    }
    .webLinks {
        display: flex; 
        flex-direction: column; 
        gap: 0px;
        position: absolute; 
        top: 100%; 
        left: 0;
        right: 0;
        transform: translateY(calc( -100% - 100px ));
        z-index: -1;
        background: var(--bg-footer); 
        transition: var(--transition-main);
        pointer-events: none;
    }
    
    .webLinks.open {
        transform: translateY(0%);
        pointer-events: auto;
    }
    .link-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 13px 25px;
        border-bottom: 1px solid var(--lightBorder-color);
    }
    .link-arrow{
        width: 40px;
        height: 40px;
        display: grid;
        place-items: center;
        border: 3px solid rgba(128, 128, 128, 0.055);
        border-radius: 50%;
        transition: var(--transition-main) !important;
    }
    .nested-arrow{
        transform: rotate(90deg);
    }
    .nested-arrow.opened{
        transform: rotate(630deg);
    }
    .web-link {
        font-size: 18px;
    }
    
    .btn-wrapper{
        margin-left: 10px;
    }
}

@media (min-width: 992px) {
    .link-arrow{
        display: none;
    }
}

@media (max-width: 567px) {
    .the-nav {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        padding-inline: 15px;
    }
    .logo-wrapper{
        gap: 8px;
    }
    .company-name{
        line-height: 1.1;
    }
    .name{
        font-size: 18px;
    }
    .description{
        font-size: 10px;
    }
    .logo-img {
        width: 40px;
    }
}




.ourStrategy-wrapper{
    margin-bottom: 7rem;
}
.ourStrategy-title{
    text-align: center;
    margin-bottom: 3rem;
}
.strategy-btns {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
    margin-bottom: 10px; 
}
.strategy-btns .li-wrapper{
    flex: 1 15%;
    min-width: 125px;
    display: flex;
    justify-content: space-between;
    cursor: pointer; 
}
.strategy-btn {
    min-width: 100%;
    text-align: center;
    padding: 10px 18px;
    font-size: 14px;
    background-color: var(--bg-footer);
    color: var(--font-secondaryColor);
    border-radius: 5px;
    font-weight: 500; 
    transition: var(--transition-main);
}
.strategy-btn:hover {
    background-color: var(--color-primary);
    color: #fff;
}
.strategy-btn.active {
    background-color: var(--color-primary); 
    color: #fff;
}
.strategy-content {
    border: 1px solid var(--lightBorder-color);
    padding: 25px 40px;
    border-radius: 5px;
    background-color: var(--bg-footer);
    position: relative; 
    overflow: hidden;
}
.strategyPara-title,
.strategy-paragraph {
    transition: var(--transition-main);
}
.strategy-paragraph {
    color: var(--font-secondaryColor);
    max-width: 90%;
}
.strategyPara-title{
    font-weight: 500;
    font-size: 1.7rem;
    margin-bottom: 2rem;
}
.fade-out {
    opacity: 0; 
}
.fade-in {
    opacity: 1; 
}






@media (max-width:992px) {
    .ourStrategy-wrapper{
        margin-top: 7rem;
    }
}
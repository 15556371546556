



.gas-price {
    /* var(--bg-smallComponent ) */
    position: absolute;
    display: flex;
    align-items: center; 
    justify-content: space-between;
    width: 400px;
    padding: 12px 20px;
    background-color: #00000050;
    backdrop-filter: blur(5px);
    bottom: 10px;
    left: 10px;
    border-radius: 5px;
    border: 1px solid #ffffff1f;
}

/* .price {
} */

.price-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    line-height: 1.2rem; 
}

.price-title {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-primary);
}

.price-value {
    text-align: center;
    display: flex;
    gap: 10px; /* Space between items */
}

.value {
    width: 100%;
    font-weight: 700;
    font-size: 15px;
    color: #fff; 
    /* var(--font-mainColor) */
}

.date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.3;
    font-weight: 300;
    font-size: 13px;
    color: #fff; 
}


@media (max-width: 500px) {
    .gas-price{
        width: 100%;
        padding: 12px 25px;
        left: 0;
        bottom: 0;
        border-left: none;
        border-right: none;
        border-radius: 0;
        /* margin-left: 1%; */
    }
}
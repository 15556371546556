.contact-page .contact-header {
    position: relative;
    height: 63vh;
    overflow: hidden;
    margin-bottom: 3rem;
}
.contact-page .lierEffectImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--lier-fadeRight);
}
.contact-page .contact-bgImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    filter: brightness(60%);
}
.contact-page .img-content{
    position: absolute;
    top: 35%;
    left: 30px;
    /* transform: translateY(-50%); */
}
.contact-page .contact-title{
    color: #fff;
    font-size: 3.5rem;
    font-weight: 600;
    max-width: 700px;
}

























@media (max-width:992px) {
    .contact-page .contact-title{
        font-size: 3rem;
    }
}
@media (max-width:767px) {
    .contact-page .lierEffectImg {
        background: linear-gradient(to right, var(--lier-fade ) 0%, rgba(255, 255, 255, 0) 70%);
    }
    .contact-page .contact-title{
        font-size: 2.7rem;
        max-width: 550px;

    }
}
@media (max-width:567px) {
    .contact-page .contact-title{
        font-size: 2.5rem;
        max-width: 450px;
    }
}
.news-container{
    display: flex;
}

.right-side{
    flex: 1;
    padding: 25px 25px 0px 0px;
}


@media (max-width:1024px) {
    .news-container{
        flex-direction: column;
    }
    .right-side{
        flex: 1;
        padding: 25px 25px 25px 25px;
    }
}
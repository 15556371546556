.important-newsSec {
    padding: 25px;
}
.newsSec-title{
    margin-bottom: 4rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.all-importantNews {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3rem;
    padding: 60px 25px;
    border-radius: 8px;
    background-color: var(--bg-footer);
}

.important-card {
    /* height: 425px; */
    display: flex;
    flex-direction: column;
    flex: 1 30%;
    min-width: 300px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.important-imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.important-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.importantNews-date {
    font-size: 0.9rem;
    color: var(--color-primary);
    margin: 15px 0;
}

.importantNews-title {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--font-mainColor);
    margin: 10px 0;
}

.imp-btnWrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.imp-btnWrapper .arrowBtn{
    color:var(--font-iconTheme)
}
.imp-btnWrapper .cirPrimary-btn:hover .arrowBtn{
    color: #fff;
}

@media (max-width: 1200px) {
    .important-imgWrapper {
        width: 100%;
        height: 300px;
    }
}
@media (max-width: 400px) {
    .important-card {
        min-width: 100%;
    }
}
.LastProjectsSec{
    padding: 25px;
}
.LastProjectsSec-title{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:15px;
    margin-bottom: 5rem;
    text-align: center ;
}
.LastProjectsSec-title svg{
    position: relative;
    top: -2px;
}
.allLast-projects{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
}
.allLast-projects  .project{
    max-width: 100%;
}


@media (max-width: 400px) {
    .allLast-projects  .project{
        min-width: 100%;
    }
}
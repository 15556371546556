.news-card {
    flex: 1 ;
    display: flex;
    flex-direction: row;
    gap: 20px;
    border: 1px solid var(--bg-footer);
    border-top: none;
    border-left: none;
    overflow: hidden;
    transition: var(--transition-main);
    background-color: var(--bg-body);
    padding: 40px 20px 40px 0px;
}


.newsImg-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 200px;
    overflow: hidden;
}

.news-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-content {
    padding: 0px 20px;
    width: 60%;
    display: flex;
    flex-direction: column;
}

.newsCard-title {
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--font-mainColor);
}

.newsCard-date {
    font-size: 0.8rem;
    color: var(--font-veryLightColor);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-style: italic;
    font-weight: 300;
}

.newsCard-date:before {
    content: '🕒'; 
    margin-right: 5px;
    font-style: normal;
}

.newsCard-paragraph {
    font-size: .9rem;
    color: var(--font-secondaryColor);
    line-height: 1.4;
    margin: 0;
}


@media (max-width:767px) {
    .news-card {
        flex-direction: column;
        border-right: none;
        padding: 40px 0px 40px 0px;
    }
    .newsImg-wrapper {
        width: 100%;
        height: 100%;
        max-height: 60vh;
    }
    .news-content {
        padding: 0px 0px;
        width: 100%;
    }
    
}
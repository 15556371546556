/*START UP Button*/
.arrow-btn{
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: .5%;
    right: 50%;
    transform: translateX(-50%);
    z-index: 5000;
    font-size: 23px;
    border-radius: 50%;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: var(--color-primary);
    color: #fff;
    /* opacity: .5; */
    overflow: hidden;
    transition: all .3s;
   
}

.arrow-btn .lines-wrapper{
    position: relative;
    background-color: transparent;
    width: 20px;
    height: 25px;
    overflow: hidden;
}
.arrow-btn .line{
    position: absolute;
    left: 49%;
    top: 50%;
    transition: var(--transition-main);
}
.arrow-btn .line-1{
    transform: translate(-50%, -50%);
}
.arrow-btn .line-2{
    transform: translate(-50%, 100%);
}


.arrow-btn:hover .line-1{
    transform: translate(-50%, -150%);
}
.arrow-btn:hover .line-2{
    transform: translate(-50%, -50%);
}
.arrow-btn svg{
    transition: var(--transition-main);
}



@media screen and (max-width:575px){
    .arrow-btn{
        right: 50%;
        transform: translateX(50%);
        border-radius: 50%;
    }
}
/*END UP Button*/
.tertiary-btn{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 25px;
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    border: 2px solid var(--color-primary);
    border-radius: 50px;
    cursor: pointer;
    transition: var(--transition-animation);
    overflow: hidden;
}
.btn-text{
    position: static;
    z-index: 2;
}
.tertiary-btn::before{
    content: "";
    position: absolute;
    left: -100%;
    height: 100%;
    width: 100%;
    border-radius: 50px;
    border: 2px solid var(--color-primary);
    background-color: var(--color-primary);
    transition: var(--transition-animation);
    z-index: 1;
}


/*start effect*/
.tertiary-btn:hover{
    border: 2px solid transparent;
}
.tertiary-btn:hover::before{
    left: 0;
}
.MAIN-WRAPPER:has(.home-page) 
.headroom-wrapper{
    position: absolute;
    width: 100%;
}
.MAIN-WRAPPER:has(.home-page) .the-nav{
    background-color: transparent;
    backdrop-filter: blur(5px);
}
.MAIN-WRAPPER:has(.home-page)  
.the-nav:has(.nest-wrapper.active){
    background-color: var(--bg-body);
}
.MAIN-WRAPPER:has(.home-page)  
.the-nav:has(.webLinks.open){
    background-color: var(--bg-body);
}
.MAIN-WRAPPER:has(.home-page) 
.headroom--scrolled .the-nav{
    background-color: #1a2331c4;
    transition: var(--transition-main);
}





.MAIN-WRAPPER:has(.home-page) .the-nav
.company-name{
    color: #fff;
}

.MAIN-WRAPPER:has(.home-page)  
.the-nav:has(.nest-wrapper.active)
.company-name{
    color: var(--font-navColorSec);
}
.MAIN-WRAPPER:has(.home-page)  
.the-nav:has(.webLinks.open)
.company-name{
    color: var(--font-navColorSec);
}





.MAIN-WRAPPER:has(.home-page) .the-nav
.web-link{
    color: #fff;
}
.MAIN-WRAPPER:has(.home-page)  
.the-nav:has(.nest-wrapper.active)
.web-link{
    color: var(--font-navColorSec);
}
.MAIN-WRAPPER:has(.home-page)  
.the-nav:has(.webLinks.open)
.web-link{
    color: var(--font-navColorSec);
}

.MAIN-WRAPPER:has(.home-page) .the-nav
.web-link:hover {
    color: var(--color-primary);
}

.MAIN-WRAPPER:has(.home-page) .the-nav
.web-link.active{
    color: var(--color-primary);
} 





.home-page .section-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home-page .secondary-btn{
    margin-block: 2rem;
}